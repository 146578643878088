import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="widget">Widget 1</div>
      <div className="widget">Widget 2</div>
      <div className="widget">Widget 3</div>
    </div>
  );
};

export default Dashboard;