import React, { useState } from 'react';
import AdminPanel from './components/AdminPanel';
import LoginForm from './components/LoginForm';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    return <LoginForm setIsAuthenticated={setIsAuthenticated} />;
  }

  return <AdminPanel />;
}

export default App;