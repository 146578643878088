import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Blog.css'

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content: '',
    author: ''
  });

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/blog');
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('http://localhost:5000/api/blog', formData);
      setFormData({ title: '', description: '', content: '', author: '' }); // Clear form data after submission
      fetchBlogPosts(); // Fetch updated blog posts
    } catch (error) {
      console.error('Error creating blog post:', error);
    }
  };

  return (
    <div>
      <h1>Blog Management</h1>
      <h1 className='warning'>NOT WORKING - DO NOT USE!!</h1>
      <h2>Create New Post</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="title" 
          placeholder="Title" 
          value={formData.title} 
          onChange={(e) => setFormData(prevState => ({ ...prevState, title: e.target.value }))} 
          required 
        />
        <input 
          type="text" 
          name="description" 
          placeholder="Description" 
          value={formData.description} 
          onChange={(e) => setFormData(prevState => ({ ...prevState, description: e.target.value }))} 
          required 
        />
        <textarea 
          name="content" 
          placeholder="Content" 
          value={formData.content} 
          onChange={(e) => setFormData(prevState => ({ ...prevState, content: e.target.value }))} 
          required
        ></textarea>
        <input 
          type="text" 
          name="author" 
          placeholder="Author" 
          value={formData.author} 
          onChange={(e) => setFormData(prevState => ({ ...prevState, author: e.target.value }))} 
          required 
        />
        <button type="submit">Create Post</button>
      </form>
      <h2>All Blog Posts</h2>
      <ul>
        {posts.map(post => (
          <li key={post.id}>
            <span>{post.title}</span>
            <span>{post.description}</span> {/* Display description */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blog;